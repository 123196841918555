<template>
  <div class="dashboard-admin d-flex flex-column">
    <div class="dashboard-admin-header py-2 px-3">正しく設定してください。</div>
    <div class="dashboard-admin-content">
      <div class="content-top">
        <div class="content-top-title">CDEA管理者側画面</div>
        <div class="content-top-list">
          <b-container
            ><b-row>
              <b-col cols="2"></b-col>
              <b-col cols="4"
                ><div class="content-top-list-item">
                  <router-link
                    :to="{ name: 'list owner' }"
                    class="d-flex flex-column align-items-center"
                  >
                    <div class="content-top-list-item-icon my-4">
                      <img :src="logoDefault" alt="" />
                    </div>
                    <div class="content-top-list-item-text">
                      オーナー管理
                    </div></router-link
                  >
                </div></b-col
              >
              <b-col cols="4"
                ><div
                  class="content-top-list-item d-flex flex-column align-items-center"
                >
                  <router-link
                    :to="{ name: 'list new cdea' }"
                    class="d-flex flex-column align-items-center"
                  >
                    <div class="content-top-list-item-icon my-4">
                      <img :src="logoDefault" alt="" />
                    </div>
                    <div class="content-top-list-item-text">ニュース管理</div>
                  </router-link>
                </div></b-col
              >
              <b-col cols="2"></b-col> </b-row
          ></b-container>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logoDefault from "@/assets/img/content-default.png";
export default {
  name: "Dashboard Admin",
  data() {
    return {
      logoDefault,
    };
  },
};
</script>

<style lang="scss" scoped>
.dashboard-admin {
  background: white;
  min-height: calc(100vh - 150px);
  &-header {
    border: 1px solid #c4c4c4;
    background: #f5f5f5;
  }
  &-content {
    padding: 40px 80px;
    .content-top {
      width: 100%;
      height: 400px;
      border-radius: 10px;
      padding: 20px;
      &-title {
        font-size: 25px;
        font-weight: 600;
      }
      &-list {
        &-item {
          margin-bottom: 20px;
          a {
            color: black;
            &:hover {
              text-decoration: none;
            }
          }
          &-icon {
            img {
              width: 50px;
              height: 50px;
            }
          }
          &-text {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
    }
    .content-bottom {
      margin-top: 50px;
      border: 4px solid #c4c4c4;
      width: 100%;
      border-radius: 10px;
      &-title {
        padding: 20px;
        font-size: 16px;
        font-weight: 600;
        border-bottom: 4px solid #c4c4c4;
      }
      &-content {
        padding: 20px;
      }
    }
  }
}
</style>
